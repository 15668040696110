export const awsConfig = {
  Auth: {
    identityPoolId: "us-east-2:f42c7df3-5ca1-48b9-ba0f-6026329e2ed4",
    region: "us-east-2",
    userPoolId: "us-east-2_rbDfWZw62",
    userPoolWebClientId: "71vjr8j1st3rcf1219nuud4v93",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    aws_appsync_graphqlEndpoint:
      "https://tdz6resnezbvhnb2yo2swnb3iq.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "asset",
        endpoint:
          "https://tdz6resnezbvhnb2yo2swnb3iq.appsync-api.us-east-2.amazonaws.com/graphql",
      },
      {
        name: "auctions",
        endpoint:
          "https://kaiwq62acvcx5bnqtnt62l4wmy.appsync-api.us-east-2.amazonaws.com/graphql",
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "stage-legalau-assets-static",
      region: "us-east-2",
    },
  },
};
